import Header from "../Header";
import React from "react";
import LandingPageTracking from "src/views/Order/LandingPageTracking";
// eslint-disable-next-line no-unused-vars
import Home from ".";
import {
  Banner50DataSource,
  Content50DataSource,
  Feature00DataSource,
  Footer10DataSource,
} from "./data.source";
import Banner5 from "./Banner5";
import Content5 from "./Content5";
import Feature0 from "./Feature0";
import Footer1 from "./Footer1";
const LandingPage = () => {
  return (
    <div>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <Header />
        <div
          className="home-page-wrapper banner5 pt-3 pb-3"
          style={{ height: "auto", background: "aliceblue" }}
        >
          <div className="home-page banner5-page">
            <LandingPageTracking />
          </div>
        </div>
        <Banner5
          id="Banner5_0"
          key="Banner5_0"
          dataSource={Banner50DataSource}
        />
        <Content5
          id="Content5_0"
          key="Content5_0"
          dataSource={Content50DataSource}
        />
        <Feature0
          id="Feature0_0"
          key="Feature0_0"
          dataSource={Feature00DataSource}
        />
        <Footer1
          id="Footer1_0"
          key="Footer1_0"
          dataSource={Footer10DataSource}
        />
      </div>
    </div>
  );
};

export default LandingPage;
