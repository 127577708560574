import { call, put } from 'redux-saga/effects'
import RemoveOrderHistoryActions from '../Redux/RemoveOrderHistoryRedux'
import LoginActions from '../Redux/LoginRedux'
export function * removeOrderHistory (api, {params}) {
  try {
    const res = yield call(api, params)
    if (res === 'Unauthorized') {
      yield put(LoginActions.loginFailure())
      return
    }
    if (res.data) { 
      yield put(RemoveOrderHistoryActions.removeOrderHistorySuccess(res.data)) 
    } else {
      yield put(RemoveOrderHistoryActions.removeOrderHistoryFailure(res))
    }
  } catch (error) {
    yield put(RemoveOrderHistoryActions.removeOrderHistoryFailure(error.message))
  }
}
