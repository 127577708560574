export const ROLES_LABEL = {
  0: "user",
  1: "staff",
  9: "admin",
};

export const ROLES = {
  USER: 0,
  STAFF: 1,
  ADMIN: 9,
};
