
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  removeOrderHistoryRequest: ['params'],
  removeOrderHistorySuccess: ['data'],
  removeOrderHistoryFailure: ['error'],
  clearData: null
})

export const RemoveOrderHistoryTypes = Types
export default Creators
export const RemoveOrderHistorySelector = (state) =>  state.removeOrderHistory
/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: {},
  error: null,
  fetching: false
})

/* ------------- Reducers ------------- */

export const removeOrderHistoryRequest = state => state.merge({ fetching: true, error: null, data: null })

export const removeOrderHistorySuccess = (state, { data }) => state.merge({ fetching: false, error: null, data: data })

export const removeOrderHistoryFailure = (state, { error }) => state.merge({ fetching: false, error, data: null })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REMOVE_ORDER_HISTORY_REQUEST]: removeOrderHistoryRequest,
  [Types.REMOVE_ORDER_HISTORY_SUCCESS]: removeOrderHistorySuccess,
  [Types.REMOVE_ORDER_HISTORY_FAILURE]: removeOrderHistoryFailure
})
