import React, { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CFormInput,
  CFormSelect,
  CPagination,
  CPaginationItem,
  CRow,
  CTable,
} from "@coreui/react";
import { ListOrderSelector, ListOrderTypes } from "src/Redux/ListOrderRedux";
import { alertError } from "../../Utils/alert.error";
import { Link } from "react-router-dom";
import { loginStateSelector } from "src/Redux/LoginRedux";
import { loginTokenSelector } from "src/Redux/LoginRedux";
import { STATUSES } from "./UpdateOrderComponent";

const fields = [
  "orderTransportNumber",
  "receiverAddress",
  "receiverCompany",
  "receiverCountry",
  "senderAddress",
  "senderCompany",
  "senderName",
  "status",
  "updated_at",
];
const COLORS = {
  created: "#FFFC33",
  picked_up: "#FFBD33",
  custom_clearance_complete: "#DBFF33",
  in_transit: "#75FF33",
  arrived: "#33FF57",
  delivery: "#33FFBD",
  delivered: "#33F1FF",
  cancelled: "#FF3336",
};
const LIMIT = 10;
const ALL_STATUSES = ["all", ...STATUSES];
const ListOrder = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const listOrderState = useSelector(ListOrderSelector);
  const [search, setSearch] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [status, setStatus] = useState(STATUSES[0]);
  const [page, setPage] = useState(1);
  const searchHandler = useCallback(
    (_page, _status) => {
      dispatch({
        type: ListOrderTypes.LIST_ORDER_REQUEST,
        params: {
          orderTransportNumber: search,
          start,
          end,
          status: _status,
          page: _page,
          limit: LIMIT,
        },
      });
    },
    [dispatch, end, search, start],
  );

  useEffect(() => {
    searchHandler(page, status);
  }, [searchHandler, page, status]);

  useEffect(() => {
    if (listOrderState.error) {
      alertError(t(listOrderState.error.message));
    }
  }, [listOrderState, t]);

  const { orders, metaData } = useMemo(() => {
    return {
      orders:
        listOrderState && listOrderState.data && listOrderState.data.data
          ? listOrderState.data.data
          : [],
      metaData: listOrderState?.data?.metaData || {},
    };
  }, [listOrderState]);
  console.log(
    "🚀 ~ file: ListOrder.js:86 ~ const{orders,metaData}=useMemo ~ metaData:",
    metaData,
  );
  const maxPage = useMemo(() => {
    return metaData?.count ? Math.ceil(metaData.count / parseInt(LIMIT)) : 1;
  }, [metaData.count]);

  const renderOrder = (order) => {
    return (
      <tr key={order.orderTransportNumber}>
        {fields.map((field) => {
          switch (field) {
            case "updated_at":
              return (
                <td key={field}>
                  {moment(order[field]).format("YYYY/MM/DD HH:mm")}
                </td>
              );
            case "orderTransportNumber":
              return (
                <td key={field}>
                  <Link to={`${order[field]}`}>{order[field]}</Link>
                </td>
              );
            case "status":
              return (
                <td key={field}>
                  <CBadge
                    style={{ background: "black", color: COLORS[order[field]] }}
                  >
                    {t(order[field])}
                  </CBadge>
                </td>
              );
            default:
              return <td key={field}>{order[field]}</td>;
          }
        })}
      </tr>
    );
  };

  return (
    <div className="animated fadeIn">
      <CCard>
        <CCardBody>
          <CRow>
            <CCol xs="12" md="12">
              <CRow style={{ marginBottom: 10 }}>
                <CCol xs={2}>
                  <CFormInput
                    label={t("orderTransportNumber")}
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                  />
                </CCol>
                <CCol xs={3}>
                  <CFormInput
                    label={t("search_from")}
                    type="datetime-local"
                    value={start}
                    onChange={(event) => setStart(event.target.value)}
                  />
                </CCol>
                <CCol xs={3}>
                  <CFormInput
                    label={t("search_to")}
                    type="datetime-local"
                    value={end}
                    onChange={(event) => setEnd(event.target.value)}
                  />
                </CCol>
                <CCol xs={3}>
                  <CFormSelect
                    label={t("status")}
                    type="select"
                    name="select"
                    id="select"
                    value={status}
                    placeholder="All"
                    onChange={(event) => {
                      setPage(1);
                      setStatus(event.target.value);
                    }}
                  >
                    {ALL_STATUSES.map((status) => (
                      <option key={status} value={status}>
                        {t(`order_history_row_${status}`)}
                      </option>
                    ))}
                  </CFormSelect>
                </CCol>
                <CCol
                  xs="1"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <CButton
                    disabled={listOrderState.fetching}
                    color="info"
                    onClick={() => searchHandler()}
                    style={{ width: 100 }}
                  >
                    {t(listOrderState.fetching ? "fetching" : "search")}
                  </CButton>
                </CCol>
              </CRow>
              {orders.length > 0 && (
                <>
                  <CTable responsive>
                    <thead>
                      <tr>
                        {fields.map((field) => (
                          <th key={field}>{t(field)}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>{orders.map((order) => renderOrder(order))}</tbody>
                  </CTable>
                  {metaData?.count && (
                    <CPagination aria-label="Page navigation example">
                      {page - 3 > 0 && (
                        <CPaginationItem
                          onClick={() => {
                            setPage(1);
                          }}
                          aria-label="Previous"
                        >
                          1
                        </CPaginationItem>
                      )}
                      {page - 2 > 0 && (
                        <CPaginationItem
                          onClick={() => {
                            setPage(page - 2);
                          }}
                        >
                          {page - 2}
                        </CPaginationItem>
                      )}
                      {page - 1 > 0 && (
                        <CPaginationItem
                          onClick={() => {
                            setPage(page - 1);
                          }}
                        >
                          {page - 1}
                        </CPaginationItem>
                      )}
                      {<CPaginationItem active>{page}</CPaginationItem>}
                      {page + 1 < maxPage && (
                        <CPaginationItem
                          onClick={() => {
                            setPage(page + 1);
                          }}
                        >
                          {page + 1}
                        </CPaginationItem>
                      )}
                      {page + 2 < maxPage && (
                        <CPaginationItem
                          onClick={() => {
                            setPage(page + 2);
                          }}
                        >
                          {page + 2}
                        </CPaginationItem>
                      )}
                      {page + 3 < maxPage && (
                        <CPaginationItem
                          onClick={() => {
                            setPage(maxPage);
                          }}
                        >
                          {maxPage}
                        </CPaginationItem>
                      )}
                    </CPagination>
                  )}
                </>
              )}
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
  );
};

export default ListOrder
