import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import UpdateOrderComponent from './UpdateOrderComponent'
import {
  CCard,
  CCol,
  CFormLabel,
  CRow,
  CCardHeader,
  CButton,
  CCardBody,
  CTable,
  CTableBody,
  CTableHead,
  CTableHeaderCell,
  CTableDataCell,
  CTableRow,
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import { alertError, alertSuccess } from "../../Utils/alert.error";
import { GetOrderTypes, GetOrderSelector } from "src/Redux/GetOrderRedux";
import { NavLink, useParams } from "react-router-dom";
import { loginStateSelector } from "src/Redux/LoginRedux";
import UpdateOrderComponent from "./UpdateOrderComponent";
import lcexpress_cut from "../../assets/images/lcexpress_cut.png";
import jsPDF from "jspdf";
import "./font";
import JsBarcode from "jsbarcode";
import { ROLES } from "src/Config/Constants";
import {
  RemoveOrderHistorySelector,
  RemoveOrderHistoryTypes,
} from "src/Redux/RemoveOrderHistoryRedux";
import { currencyFormat } from "src/Utils/Utils";
export const convertValue = (key, value, format = "YYYY-MM-DD HH:mm") => {
  switch (key) {
    case "updated_at":
    case "created_at":
      return moment(value).format(format);
    default:
      return value;
  }
};
const DetailOrder = () => {
  const myRef = React.createRef();
  const myCanvas = React.createRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getOrderState = useSelector(GetOrderSelector);
  const { id } = useParams();
  console.log("matches", id);
  const getOrder = useCallback(
    (id) => {
      dispatch({
        type: GetOrderTypes.GET_ORDER_REQUEST,
        params: id,
      });
    },
    [dispatch],
  );
  useEffect(() => {
    getOrder(id);
  }, [getOrder, id]);

  useEffect(() => {
    if (getOrderState.error) {
      alertError(t(getOrderState.error.message));
    }
  }, [getOrderState, t]);

  const role = useSelector(loginStateSelector)?.data?.role;
  const order = getOrderState?.data || {};

  const [fetching, setFetching] = useState(false);
  const removeOrderHistory = (params) => {
    setFetching(true);
    dispatch({
      type: RemoveOrderHistoryTypes.REMOVE_ORDER_HISTORY_REQUEST,
      params,
    });
  };

  const removeOrderHistoryState = useSelector(RemoveOrderHistorySelector);
  useEffect(() => {
    if (fetching && !removeOrderHistoryState.fetching) {
      if (removeOrderHistoryState.error) {
        alertError(t(removeOrderHistoryState.error.message));
      } else if (removeOrderHistoryState.data && removeOrderHistoryState.data) {
        alertSuccess(t("update_success"));
        getOrder(id);
      }
    }
  }, [
    removeOrderHistoryState.fetching,
    getOrder,
    fetching,
    removeOrderHistoryState.error,
    removeOrderHistoryState.data,
    t,
    id,
  ]);

  const billWidth = 1122;

  const [printing, setPrinting] = useState(false);
  useEffect(() => {
    if (order.orderTransportNumber)
      JsBarcode(myCanvas.current, order.orderTransportNumber, { displayValue : false});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.orderTransportNumber]);
  const genPdf = async () => {
    setPrinting(true);
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
      orientation: "landscape",
      hotfixes: ["px_scaling"],
    });
    doc.setFont("SVN-Segoe UI");

    var jpegUrl = myCanvas.current.toDataURL("image/jpeg");
    doc.addImage(jpegUrl, "JPEG", 0, 0);
    await doc.html(myRef.current, {
      callback(doc) {
        var blobURL = URL.createObjectURL(doc.output("blob"));
        const iframe = document.createElement("iframe"); //load content in an iframe to print later
        document.body.appendChild(iframe);
        iframe.style.display = "none";
        iframe.src = blobURL;
        iframe.onload = () => {
          iframe.focus();
          setPrinting(false);
          iframe.contentWindow.print();
        };
      },
      margin: [0, 0, 0, 0],
      html2canvas: {
        scale: 1,
      },
    });
  };
  const renderRow = (key, value) => {
    return (
      <CRow CRow key={key}>
        <CCol md="5">
          <CFormLabel style={{ margin: 5 }}>{t(key)}</CFormLabel>
        </CCol>
        <CCol xs="12" md="7">
          <p className="form-control-static">{convertValue(key, value)}</p>
        </CCol>
      </CRow>
    );
  };
  const renderLine = () => {
    return (
      <div
        style={{
          height: 1,
          width: "100%",
          backgroundColor: "black",
          marginBottom: 10,
        }}
      />
    );
  };
  const renderBill = (order) => {
    if (!order.orderTransportNumber) return;
    const { packageValue } = order;
    let packageWeight = {};
    let totalWeight = 0;
    try {
      packageWeight = JSON.parse(order.packageWeight);
      Object.values(packageWeight).forEach((value, key) => {
        totalWeight += parseFloat(value.weight * (value.quantity || 1));
      });
    } catch (e) {
      console.log(e);
    }
    const columnWidths = [1, 2, 2, 4, 3];
    return (
      <CCol
        ref={myRef}
        style={{
          paddingLeft: 40,
          margin: "auto",
          width: billWidth,
          height: "fit-content",
          paddingRight: 40,
          fontWeight: 500,
          color: "black",
          fontFamily: "Segoe UI",
        }}
      >
        <CRow>
          <CCol md="2">
            <img
              alt="logo"
              src={lcexpress_cut}
              style={{ width: 150, height: 100, marginTop: 20 }}
            />
            <CRow>Account No: {order.userId}</CRow>
          </CCol>
          <CCol
            md="7"
            style={{
              fontSize: "large",
              fontWeight: "600",
              paddingLeft: 20,
              paddingTop: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CRow>L&C EXPRESS TRANSPORT CO., LTD</CRow>
            <CRow>105 Nguyen Xien, Ha Dinh, Thanh Xuan, Ha Noi</CRow>
            <CRow>02432018309 - 0948843553 - https://lcexpress.vn</CRow>
          </CCol>
          <CCol md="3" style={{ alignItems: "flex-end" }}>
            {/* {order && <Barcode id="barcode" value={order.orderTransportNumber + ''} />} */}
            {
              <canvas
                ref={myCanvas}
                id="barcode"
                style={{ width: 178, height: 122 }}
              />
            }
            <CRow>
              <CCol
                md="7"
                style={{
                  fontSize: "large",
                  fontWeight: "600",
                  textAlign: 'center'
                }}
              >
                {order.orderTransportNumber}
              </CCol>
            </CRow>
          </CCol>
        </CRow>

        <CRow>
          <CCol style={{ border: "1px solid black" }}>
            <CRow className="text-info-test">SHIPPER/EXPORTER</CRow>
            <CRow>
              <CCol>Company: {order.senderCompany}</CCol>
            </CRow>
            <CRow>
              <CCol>Add: {order.senderAddress}</CCol>
            </CRow>
            <CRow>
              <CCol>Name: {order.senderName}</CCol>
            </CRow>
          </CCol>
          <CCol style={{ border: "1px solid black" }}>
            <CRow className="text-info-test">CONSIGNEE/IMPORTER</CRow>
            <CRow>
              <CCol>Company: {order.receiverCompany}</CCol>
            </CRow>
            <CRow>
              <CCol>
                Add: {order.receiverAddress} {order.receiverCountry}
              </CCol>
            </CRow>
            <CRow>
              <CCol>Name: {order.receiverName}</CCol>
            </CRow>
            <CRow>
              <CCol>Tel: {order.receiverPhone}</CCol>
            </CRow>
          </CCol>
        </CRow>

        <CRow>
          <CCol style={{ border: "1px solid black" }}>
            <CRow className="text-info-test">{order.packageGoodName}</CRow>
          </CCol>
          <CCol style={{ border: "1px solid black", textAlign: "center" }}>
            <CRow className="text-info-test">
              <CCol md={columnWidths[0]} style={{ padding: 0 }}>
                Pkg
              </CCol>
              <CCol
                md={columnWidths[1]}
                style={{ padding: 0, borderLeft: "1px solid black" }}
              >
                Quantity
              </CCol>
              <CCol
                md={columnWidths[2]}
                style={{ padding: 0, borderLeft: "1px solid black" }}
              >
                Wt (Kg)
              </CCol>

              <CCol
                md={columnWidths[3]}
                style={{ borderLeft: "1px solid black" }}
              >
                WxDxH (cm)
              </CCol>
              <CCol
                md={columnWidths[4]}
                style={{ padding: 0, borderLeft: "1px solid black" }}
              >
                Volume Wt
              </CCol>
            </CRow>
            <div style={{ background: "gray", height: 1, width: "100%" }}></div>
            {Object.values(packageWeight).map((each, index) => (
              <div key={index}>
                <CRow className="borderBottomGray">
                  <CCol md={columnWidths[0]}>{index + 1}</CCol>
                  <CCol
                    md={columnWidths[1]}
                    style={{
                      borderLeft: "1px solid black",
                      textAlign: "right",
                    }}
                  >
                    {each.quantity || 1}
                  </CCol>
                  <CCol
                    md={columnWidths[2]}
                    style={{
                      borderLeft: "1px solid black",
                      textAlign: "right",
                    }}
                  >
                    {each.weight}
                  </CCol>
                  <CCol
                    md={columnWidths[3]}
                    style={{ borderLeft: "1px solid black" }}
                  >
                    {each.w} x {each.d} x {each.h}
                  </CCol>
                  <CCol
                    md={columnWidths[4]}
                    style={{
                      borderLeft: "1px solid black",
                      textAlign: "right",
                    }}
                  >
                    {(
                      ((each.quantity || 1) * each.w * each.d * each.h) /
                      5000
                    ).toFixed(2)}
                  </CCol>
                </CRow>
                <div
                  style={{ background: "gray", height: 1, width: "100%" }}
                ></div>
              </div>
            ))}
          </CCol>
        </CRow>

        <CRow>
          <CCol style={{ border: "1px solid black" }}>
            <CRow style={{ height: 58 }}>
              <CCol style={{ height: 58 }}>{"Shipper's signature"}</CCol>
              <CCol style={{ height: 58, borderLeft: "1px solid black" }}>
                {"Pickup's signature"}
              </CCol>
            </CRow>
          </CCol>
          <CCol style={{ border: "1px solid black", height: 60 }}>
            <CRow className="text-info-test">
              <CCol style={{ padding: 0, paddingLeft: 5 }}>
                Total weight: {totalWeight}kg
              </CCol>
              <CCol>Package Value: {currencyFormat(packageValue)}</CCol>
            </CRow>
            <CRow className="text-info-test">Route:</CRow>
          </CCol>
        </CRow>

        <CRow>
          <CCol style={{ border: "1px solid black", height: 60 }}>
            <CRow className="text-info-test">
              <CCol>{"Consignee's signature"}</CCol>
              <CCol></CCol>
            </CRow>
          </CCol>
          <CCol style={{ border: "1px solid black" }}>
            <CRow className="text-info-test">
              {"Date"}
              <div style={{ color: "black" }}>
                {convertValue("created_at", order.created_at, "YYYY-MM-DD")}
              </div>
            </CRow>
          </CCol>
        </CRow>
      </CCol>
    );
  };
  const renderOrder = (order) => {
    return (
      <CCol>
        <CRow className="mb-3">
          <CCol md="6">
            <CCard>
              <CCardHeader>
                <strong>{t("sender")}</strong>
              </CCardHeader>
              <CCardBody>
                {["senderAddress", "senderCompany", "senderName"].map((key) =>
                  renderRow(key, order[key]),
                )}
              </CCardBody>
            </CCard>
          </CCol>
          <CCol md="6">
            <CCard>
              <CCardHeader>
                <strong>{t("receiver")}</strong>
              </CCardHeader>
              <CCardBody>
                {[
                  "receiverAddress",
                  "receiverCompany",
                  "receiverCountry",
                  "receiverName",
                  "receiverPhone",
                ].map((key) => renderRow(key, order[key]))}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <CRow className="mb-3">
          <CCol md="6">
            <CCard>
              <CCardHeader>
                <strong>{t("packageInfo")}</strong>
              </CCardHeader>
              <CCardBody>
                {["packageGoodName", "packageNo"].map((key) =>
                  renderRow(key, order[key]),
                )}
              </CCardBody>
            </CCard>
          </CCol>
          <CCol md="6">
            <CCard>
              <CCardHeader>
                <strong>{t("Status")}</strong>
              </CCardHeader>
              <CCardBody>
                <CRow CRow>
                  <CCol md="5">
                    <CFormLabel>{t("status")}</CFormLabel>
                  </CCol>
                  <CCol xs="12" md="7">
                    <p className="form-control-static">
                      {convertValue(
                        "status",
                        t("order_history_row_" + order.status),
                      )}
                    </p>
                  </CCol>
                </CRow>
                {["created_at", "updated_at"].map((key) =>
                  renderRow(key, order[key]),
                )}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        {role >= ROLES.STAFF && <UpdateOrderComponent order={order} />}
        <CRow className="mb-3 mt-3">
          <CCol>
            <CCard>
              <CCardHeader>
                <strong>{t("history")}</strong>
              </CCardHeader>
              <CCardBody>
                <CTable bordered>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell>{t("created_at")}</CTableHeaderCell>
                      <CTableHeaderCell>{t("status")}</CTableHeaderCell>
                      <CTableHeaderCell>{t("country")}</CTableHeaderCell>
                      {role >= ROLES.STAFF && (
                        <CTableHeaderCell>{t("action")}</CTableHeaderCell>
                      )}
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {order.histories &&
                      order.histories.map((each, index) => {
                        return (
                          <CTableRow CRow key={index}>
                            <CTableDataCell>
                              {convertValue("created_at", each.created_at)}
                            </CTableDataCell>
                            <CTableDataCell>
                              {t(`order_history_row_${each.status}`)}
                            </CTableDataCell>
                            <CTableDataCell md="6">
                              {each.country}
                            </CTableDataCell>
                            {role >= ROLES.STAFF && (
                              <CTableDataCell>
                                <CButton
                                  color={"danger"}
                                  onClick={() => {
                                    removeOrderHistory({
                                      id: each.id,
                                    });
                                  }}
                                >
                                  {t("delete")}
                                </CButton>
                              </CTableDataCell>
                            )}
                          </CTableRow>
                        );
                      })}
                  </CTableBody>
                </CTable>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCol>
    );
  };

  return (
    <div className="animated fadeIn">
      <CRow>
        <CCol xs="12" md={"12"}>
          {renderOrder(order)}
          <CCard className="mb-3">
            <CCardHeader>
              <CButton style={{ marginRight: 10 }} to="/" component={NavLink}>
                {t("home")}
              </CButton>
              <CButton
                disabled={printing}
                color="success"
                onClick={() => genPdf()}
              >
                {t(printing ? "printing" : "print")}
              </CButton>
            </CCardHeader>
            <CCardBody>{renderBill(order)}</CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  );
};

export default DetailOrder
