import { call, put } from 'redux-saga/effects'
import RemoveAddressActions from '../Redux/RemoveAddressRedux'
import LoginActions from '../Redux/LoginRedux'
export function* removeAddress(api, { params }) {
  try {
    const res = yield call(api, params)
    if (res === 'Unauthorized') {
      yield put(LoginActions.loginFailure())
      return
    }
    if (res.data) {
      yield put(RemoveAddressActions.removeAddressSuccess(res.data))
    } else {
      yield put(RemoveAddressActions.removeAddressFailure(res))
    }
  } catch (error) {
    yield put(RemoveAddressActions.removeAddressFailure(error.message))
  }
}
