import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getAddressesRequest: ["params"],
  getAddressesSuccess: ["data"],
  getAddressesFailure: ["error"],
  clearData: null,
});

export const GetAddressesTypes = Types;
export default Creators;
export const GetAddressesSelector = (state) => state.getAddresses;
/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: {},
  error: null,
  fetching: false,
});

/* ------------- Reducers ------------- */

export const getAddressesRequest = (state) =>
  state.merge({ fetching: true, error: null, data: null });

export const getAddressesSuccess = (state, { data }) =>
  state.merge({ fetching: false, error: null, data: data });

export const getAddressesFailure = (state, { error }) =>
  state.merge({ fetching: false, error, data: null });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ADDRESSES_REQUEST]: getAddressesRequest,
  [Types.GET_ADDRESSES_SUCCESS]: getAddressesSuccess,
  [Types.GET_ADDRESSES_FAILURE]: getAddressesFailure,
});
