import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  trackOrderRequest: ["params"],
  trackOrderSuccess: ["data"],
  trackOrderFailure: ["error"],
  clearData: null,
});

export const TrackOrderTypes = Types;
export default Creators;
export const TrackOrderSelector = (state) => state.trackOrder;
/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: {},
  error: null,
  fetching: false,
});

/* ------------- Reducers ------------- */

export const trackOrderRequest = (state) =>
  state.merge({ fetching: true, error: null, data: null });

export const trackOrderSuccess = (state, { data }) =>
  state.merge({ fetching: false, error: null, data: data });

export const trackOrderFailure = (state, { error }) =>
  state.merge({ fetching: false, error, data: null });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.TRACK_ORDER_REQUEST]: trackOrderRequest,
  [Types.TRACK_ORDER_SUCCESS]: trackOrderSuccess,
  [Types.TRACK_ORDER_FAILURE]: trackOrderFailure,
});
