import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  updateUserRequest: ['params'],
  updateUserSuccess: ['data'],
  updateUserFailure: ['error'],
  clearData: null,
})

export const UpdateUserTypes = Types
export default Creators
export const UpdateUserSelector = (state) => state.updateUser
/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: {},
  error: null,
  fetching: false,
})

/* ------------- Reducers ------------- */

export const updateUserRequest = (state) => state.merge({ fetching: true, error: null, data: null })

export const updateUserSuccess = (state, { data }) =>
  state.merge({ fetching: false, error: null, data: data })

export const updateUserFailure = (state, { error }) =>
  state.merge({ fetching: false, error, data: null })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPDATE_USER_REQUEST]: updateUserRequest,
  [Types.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [Types.UPDATE_USER_FAILURE]: updateUserFailure,
})
