import moment from 'moment'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
// import UpdateOrderComponent from './UpdateOrderComponent'
import {
  CCard,
  CCol,
  CFormLabel,
  CRow,
  CCardHeader,
  CButton,
  CCardBody,
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import { alertError } from "../../Utils/alert.error";
import { TrackOrderTypes, TrackOrderSelector } from "src/Redux/TrackOrderRedux";
import { NavLink, useParams } from "react-router-dom";
import { loginStateSelector } from "src/Redux/LoginRedux";
import { ROLES } from "src/Config/Constants";

const TrackingOrder = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getOrderState = useSelector(TrackOrderSelector);
  const { id } = useParams();
  console.log("matches", id);
  const getOrder = useCallback(
    (id) => {
      dispatch({
        type: TrackOrderTypes.TRACK_ORDER_REQUEST,
        params: id,
      });
    },
    [dispatch],
  );
  useEffect(() => {
    getOrder(id);
  }, [getOrder, id]);

  useEffect(() => {
    if (getOrderState.error) {
      alertError(t(getOrderState.error.message));
    }
  }, [getOrderState, t]);

  const role = useSelector(loginStateSelector)?.data?.role;
  const order = getOrderState?.data || {};
  const convertValue = (key, value) => {
    switch (key) {
      case "updated_at":
      case "created_at":
        return moment(value).format("YYYY-MM-DD HH:mm");
      default:
        return value;
    }
  };

  const renderRow = (key, value) => {
    return (
      <CRow CRow key={key}>
        <CCol md="5">
          <CFormLabel>{t(key)}</CFormLabel>
        </CCol>
        <CCol xs="12" md="7">
          <p className="form-control-static">{convertValue(key, value)}</p>
        </CCol>
      </CRow>
    );
  };
  const renderLine = () => {
    return (
      <div
        style={{
          height: 1,
          width: "100%",
          backgroundColor: "black",
          marginBottom: 10,
        }}
      />
    );
  };

  const renderOrder = (order) => {
    return (
      <CCol>
        {renderLine()}
        <CRow>
          <CCol md="6">
            <strong>{t("sender")}</strong>
            {["senderAddress"].map((key) => renderRow(key, order[key]))}
          </CCol>
          <CCol md="6">
            <strong>{t("receiver")}</strong>
            {["receiverCompany", "receiverCountry", "receiverName"].map((key) =>
              renderRow(key, order[key]),
            )}
          </CCol>
        </CRow>
        {renderLine()}
        <CRow>
          <CCol md="6">
            <strong>{t("packageInfo")}</strong>
            {["packageGoodName", "packageNo"].map((key) =>
              renderRow(key, order[key]),
            )}
          </CCol>
          <CCol md="6">
            <strong>{t("Status")}</strong>
            <CRow CRow>
              <CCol md="5">
                <CFormLabel>{t("status")}</CFormLabel>
              </CCol>
              <CCol xs="12" md="7">
                <p className="form-control-static">
                  {convertValue(
                    "status",
                    t("order_history_row_" + order.status),
                  )}
                </p>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        {renderLine()}
        <CRow>
          <CCol>
            <CRow>
              <strong style={{ fontSize: 40, textAlign: "center" }}>
                {t("history")}
              </strong>
            </CRow>
            <CRow>
              <CCol md="3">
                <CFormLabel>{t("created_at")}</CFormLabel>
              </CCol>
              <CCol xs="12" md="6">
                <p className="form-control-static">{t(`shipment`)}</p>
              </CCol>
              <CCol md="3">
                <CFormLabel>{t("country")}</CFormLabel>
              </CCol>
            </CRow>
            <div style={{ height: 1, backgroundColor: "black" }} />
            {order.histories &&
              order.histories.map((each, index) => {
                return (
                  <CRow key={index}>
                    <CCol md="3">
                      <CFormLabel>
                        {convertValue("created_at", each.created_at)}
                      </CFormLabel>
                    </CCol>
                    <CCol xs="12" md="6">
                      <p className="form-control-static">
                        {t(`order_history_row_${each.status}`)}
                      </p>
                    </CCol>
                    <CCol md="3">
                      <CFormLabel>{each.country}</CFormLabel>
                    </CCol>
                  </CRow>
                );
              })}
          </CCol>
        </CRow>
      </CCol>
    );
  };

  return (
    <div className="animated fadeIn">
      <CRow>
        <CCol xs="12" md={role >= ROLES.STAFF ? "9" : "12"}>
          <CCard>
            <CCardHeader>
              <CButton style={{ marginRight: 10 }} to="/" component={NavLink}>
                {t("home")}
              </CButton>
            </CCardHeader>
            <CCardBody>
              {renderOrder(order)}
              {renderLine()}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  );
};

export default TrackingOrder
