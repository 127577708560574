import { call, put } from 'redux-saga/effects'
import UpdateUserActions from '../Redux/UpdateUserRedux'
import LoginActions from '../Redux/LoginRedux'
export function* updateUser(api, { params }) {
  try {
    const res = yield call(api, params)
    if (res === 'Unauthorized') {
      yield put(LoginActions.loginFailure())
      return
    }
    if (res.data) {
      yield put(UpdateUserActions.updateUserSuccess(res.data))
    } else {
      yield put(UpdateUserActions.updateUserFailure(res))
    }
  } catch (error) {
    yield put(UpdateUserActions.updateUserFailure(error.message))
  }
}
