import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  removeAddressRequest: ['params'],
  removeAddressSuccess: ['data'],
  removeAddressFailure: ['error'],
  clearData: null,
})

export const RemoveAddressTypes = Types
export default Creators
export const RemoveAddressSelector = (state) => state.removeAddress
/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: {},
  error: null,
  fetching: false,
})

/* ------------- Reducers ------------- */

export const removeAddressRequest = (state) =>
  state.merge({ fetching: true, error: null, data: null })

export const removeAddressSuccess = (state, { data }) =>
  state.merge({ fetching: false, error: null, data: data })

export const removeAddressFailure = (state, { error }) =>
  state.merge({ fetching: false, error, data: null })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REMOVE_ADDRESS_REQUEST]: removeAddressRequest,
  [Types.REMOVE_ADDRESS_SUCCESS]: removeAddressSuccess,
  [Types.REMOVE_ADDRESS_FAILURE]: removeAddressFailure,
})
