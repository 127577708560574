import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  listUserRequest: ['params'],
  listUserSuccess: ['data'],
  listUserFailure: ['error'],
  clearData: null,
})

export const ListUserTypes = Types
export default Creators
export const ListUserSelector = (state) => state.listUser
/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: {},
  error: null,
  fetching: false,
})

/* ------------- Reducers ------------- */

export const listUserRequest = (state) => state.merge({ fetching: true, error: null, data: null })

export const listUserSuccess = (state, { data }) =>
  state.merge({ fetching: false, error: null, data: data })

export const listUserFailure = (state, { error }) =>
  state.merge({ fetching: false, error, data: null })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LIST_USER_REQUEST]: listUserRequest,
  [Types.LIST_USER_SUCCESS]: listUserSuccess,
  [Types.LIST_USER_FAILURE]: listUserFailure,
})
